// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import css from "../css/app.css"

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import dependencies
//
import "phoenix_html"

// uPlot
////import uPlot from 'uplot'

// D3
import { select } from 'd3-selection'
const chartData = [30, 86, 168, 281, 303, 365]

select('.homepage_chartx')
    .selectAll('div')
    .data(chartData)
    .enter()
    .append('div')
    .style('width', d => d.toString() + 'px')
    .text(d => d)

// set the dimensions and margins of the graph
let margin = { top: 10, right: 30, bottom: 30, left: 60 }
let width = 460 - margin.left - margin.right
let height = 400 - margin.top - margin.bottom

// append the svg object to the body of the page
//var svg = d3.select("#my_dataviz")
select('.homepage_chart')
    .selectAll('div')
    .data(chartData)
    .enter()
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

//Read the data
//d3.csv("https://raw.githubusercontent.com/holtzy/data_to_viz/master/Example_dataset/3_TwoNumOrdered_comma.csv",

    // When reading the csv, I must format variables:
    // function (d) {
    //     return { date: d3.timeParse("%Y-%m-%d")(d.date), value: d.value }
    // },

    // Now I can use this dataset:
    // function (data) {

    //     // Add X axis --> it is a date format
    //     var x = d3.scaleTime()
    //         .domain(d3.extent(data, function (d) { return d.date; }))
    //         .range([0, width]);
    //     svg.append("g")
    //         .attr("transform", "translate(0," + height + ")")
    //         .call(d3.axisBottom(x));

    //     // Add Y axis
    //     var y = d3.scaleLinear()
    //         .domain([0, d3.max(data, function (d) { return +d.value; })])
    //         .range([height, 0]);
    //     svg.append("g")
    //         .call(d3.axisLeft(y));

    //     // Add the line
    //     svg.append("path")
    //         .datum(data)
    //         .attr("fill", "none")
    //         .attr("stroke", "steelblue")
    //         .attr("stroke-width", 1.5)
    //         .attr("d", d3.line()
    //             .x(function (d) { return x(d.date) })
    //             .y(function (d) { return y(d.value) })
    //         )

    // })

let Hooks = {}
Hooks.MobileIndexChart = {
    mounted() {
        console.log("app.js mounted MobileIndexChart");
        // var chart = new Chart(ctx, cfg);
//        let uplot = new uPlot(opts2, data2, document.getElementById("uplot"));
  //      window.addEventListener("resize", throttle(() => uplot.setSize(getSize()), 100));
        drawGraph();

    },
    updated() {
        console.log("app.js updated MobileIndexChart");
        // var cfg = {
        //     data: {
        //         datasets: [{
        //             backgroundColor: color("#1f77b4").alpha(0.5).rgbString(),
        //             borderColor: "#1f77b4",
        //             data: liveData(),
        //             type: 'line',
        //             pointRadius: 0,
        //             fill: false,
        //             lineTension: 0,
        //             borderWidth: 1.5
        //         }]
        //     },
        //     options: {
        //         animation: {
        //             duration: 0
        //         },
        //         legend: {
        //             display: false
        //         },
        //         scales: {
        //             xAxes: [{
        //                 type: 'time',
        //                 time: {
        //                     tooltipFormat: "h:mm a",
        //                     displayFormats: {
        //                         hour: 'h a'
        //                     }
        //                 },
        //                 xtooltipFormat: "h:mm:ss a",
        //                 xdisplayFormats: {
        //                     second: 'h:mm:ss a'
        //                 },
        //                 distribution: 'series',
        //                 offset: true,
        //                 gridLines: {
        //                     zeroLineWidth: 5,
        //                     zeroLineColor: "#ffffff",
        //                 },
        //                 ticks: {
        //                     major: {
        //                         enabled: true,
        //                         fontStyle: 'bold'
        //                     },
        //                     source: 'data',
        //                     autoSkip: true,
        //                     autoSkipPadding: 75,
        //                     maxRotation: 0,
        //                     sampleSize: 100
        //                 },
        //                 afterBuildTicks: function (scale, ticks) {
        //                     var majorUnit = scale._majorUnit;
        //                     var firstTick = ticks[0];
        //                     var i, ilen, val, tick, currMajor, lastMajor;
        //                     // ticks[0].value 1584446400000
        //                     //val = moment(ticks[0].value);
        //                     //                                        console.log(DateTime.fromMillis(ticks[0].value, {zone: 'America/New_York'}).zoneName)

        //                     val = DateTime.fromMillis(ticks[0].value);
        //                     /*							if ((majorUnit === 'minute' && val.second() === 0)
        //                                                         || (majorUnit === 'hour' && val.minute() === 0)
        //                                                         || (majorUnit === 'day' && val.hour() === 8)
        //                                                         || (majorUnit === 'month' && val.date() <= 3 && val.isoWeekday() === 1)
        //                                                         || (majorUnit === 'year' && val.month() === 0)) {*/
        //                     if ((majorUnit === 'minute' && val.second === 0)
        //                         || (majorUnit === 'hour' && val.minute === 0)
        //                         || (majorUnit === 'day' && val.hour === 8)
        //                         || (majorUnit === 'month' && val.date <= 3 && val.isoWeekday === 1)
        //                         || (majorUnit === 'year' && val.month === 0)) {
        //                         firstTick.major = true;
        //                     } else {
        //                         firstTick.major = false;
        //                     }
        //                     lastMajor = val.get(majorUnit);

        //                     for (i = 1, ilen = ticks.length; i < ilen; i++) {
        //                         tick = ticks[i];
        //                         // tick.value 1584446460000
        //                         //val = moment(tick.value);
        //                         val = DateTime.fromMillis(tick.value);
        //                         currMajor = val.get(majorUnit);
        //                         tick.major = currMajor !== lastMajor;
        //                         lastMajor = currMajor;
        //                     }
        //                     return ticks;
        //                 }
        //             }],
        //             yAxes: [{
        //                 gridLines: {
        //                     zeroLineWidth: 5,
        //                     zeroLineColor: "#ffffff",
        //                     drawBorder: false
        //                 },
        //                 ticks: {
        //                     // Include a dollar sign in the ticks
        //                     callback: function (value, index, values) {
        //                         return yAxisLabel(value);
        //                     }
        //                 }
        //             }]
        //         },
        //         tooltips: {
        //             intersect: false,
        //             mode: 'index',
        //             callbacks: {
        //                 label: function (tooltipItem, myData) {
        //                     var label = myData.datasets[tooltipItem.datasetIndex].label || '';
        //                     if (label) {
        //                         label += ': ';
        //                     }
        //                     label += parseFloat(tooltipItem.value).toFixed(2);
        //                     return label;
        //                 }
        //             }
        //         }
        //     }
        // };
              //  var chart = new Chart(ctx, cfg);
//        let data2 = [
  //          uPlotXData(),
    //        uPlotYData()
      //  ];
     //   let uplot = new uPlot(opts2, data2, document.getElementById("uplot"));
      //  window.addEventListener("resize", throttle(() => uplot.setSize(getSize()), 100));
drawGraph();
    },
}

Hooks.MobileCharts = {
    mounted() {
        console.log("app.js mounted MobileCharts");
//        var chart = new Chart(ctx, cfg);
        // uPlot
        drawGraph();
    },
    updated() {
        console.log("app.js updated MobileCharts");
        // var cfg = {
        //     type: 'line',
        //     data: {
        //         datasets: [{
        //             backgroundColor: color("#1f77b4").alpha(0.5).rgbString(),
        //             borderColor: "#1f77b4",
        //             data: liveData(),
        //             pointRadius: 0,
        //             fill: false,
        //             lineTension: 0,
        //             borderWidth: 1.5
        //         }]
        //     },
        //     options: {
        //         animation: {
        //             duration: 0
        //         },
        //         legend: {
        //             display: false
        //         },
        //         scales: {
        //             xAxes: [{
        //                 type: 'time',
        //                 time30xxx: {
        //                     tooltipFormat: "M/d",
        //                     displayFormats: {
        //                         day: 'M/d'
        //                     },
        //                     unitStepSize: 1.0,
        //                     unit: 'day'
        //                 },
        //                 time: {
        //                     tooltipFormat: "M/d h:mm",
        //                     displayFormats: {
        //                         second: 'M/d',
        //                         minute: 'M/d',
        //                         hour: 'M/d',
        //                         xday: 'M/d-',
        //                         day: 'M/d',
        //                         month: 'M/d'
        //                     },
        //                     unitStepSize: 1,
        //                     stepSize: 1,
        //                     unit: 'day'
        //                 },
        //                 distribution: 'series',
        //                 offset: true,
        //                 gridLines: {
        //                     zeroLineWidth: 5,
        //                     zeroLineColor: "#ffffff",
        //                 },
        //                 ticks: {
        //                     major: {
        //                         enabled: true,
        //                         fontStyle: 'bold'
        //                     },
        //                     source: 'data',
        //                     autoSkip: true,
        //                     autoSkipPadding: 100,
        //                     maxRotation: 0,
        //                     sampleSize: 100
        //                 },
        //                 afterBuildTicks: function (scale, ticks) {
        //                     var majorUnit = scale._majorUnit;
        //                     console.log(majorUnit);
        //                     majorUnit = 'day'
        //                     var firstTick = ticks[0];
        //                     var i, ilen, val, tick, currMajor, lastMajor;
        //                     // ticks[0].value 1584446400000
        //                     //val = moment(ticks[0].value);
        //                     //                                        console.log(DateTime.fromMillis(ticks[0].value, {zone: 'America/New_York'}).zoneName)
        //                     val = DateTime.fromMillis(ticks[0].value);
        //                     //console.log(val.month + "/" + val.day);
        //                     //val = DateTime.fromFormat(ticks[0].value, "yyyy-MM-dd");
		// 					/* if ((majorUnit === 'minute' && val.second() === 0)
		// 							|| (majorUnit === 'hour' && val.minute() === 0)
		// 							|| (majorUnit === 'day' && val.hour() === 8)
		// 							|| (majorUnit === 'month' && val.date() <= 3 && val.isoWeekday() === 1)
		// 							|| (majorUnit === 'year' && val.month() === 0)) {*/
        //                     if ((majorUnit === 'minute' && val.second === 0)
        //                         || (majorUnit === 'hour' && val.minute === 0)
        //                         || (majorUnit === 'day' && val.hour === 8)
        //                         || (majorUnit === 'month' && val.day <= 3 && val.weekday === 1)
        //                         || (majorUnit === 'year' && val.month === 0)) {
        //                         firstTick.major = true;
        //                     } else {
        //                         firstTick.major = true;
        //                     }
        //                     lastMajor = val.get(majorUnit);

        //                     for (i = 1, ilen = ticks.length; i < ilen; i++) {
        //                         tick = ticks[i];
        //                         // tick.value 1584446460000
        //                         //val = moment(tick.value);
        //                         val = DateTime.fromMillis(tick.value);
        //                         //console.log(val.month + "//" + val.day);
        //                         //val = DateTime.fromFormat(tick.value, "yyyy-MM-dd");
        //                         currMajor = val.get(majorUnit);
        //                         // Hide March 2020 tick, don't make it major
        //                         tick.major = currMajor !== lastMajor;
        //                         lastMajor = currMajor;
        //                         //console.log(lastMajor);
        //                     }
        //                     return ticks;
        //                 }
        //             }],
        //             yAxes: [{
        //                 gridLines: {
        //                     zeroLineWidth: 5,
        //                     zeroLineColor: "#ffffff",
        //                     drawBorder: false
        //                 },
        //             }]
        //         },
        //         tooltips: {
        //             intersect: false,
        //             mode: 'index',
        //             callbacks: {
        //                 label: function (tooltipItem, myData) {
        //                     var label = myData.datasets[tooltipItem.datasetIndex].label || '';
        //                     if (label) {
        //                         label += ': ';
        //                     }
        //                     label += parseFloat(tooltipItem.value).toFixed(2);
        //                     return label;
        //                 }
        //             }
        //         }
        //     }
        // };
        //         var chart = new Chart(ctx, cfg);
        // uPlot
        drawGraph();

    },
}

// Import local files
//
// Local files can be imported directly using relative paths, for example:
import socket from "./socket"
import {Socket} from "phoenix"
import {LiveSocket} from "phoenix_live_view"
let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
let liveSocket = new LiveSocket("/live", Socket, { hooks: Hooks, params: { _csrf_token: csrfToken } });
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket

//import "./liveview_highlight.js"